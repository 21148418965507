export const dataAllFloor = [
  {
    id: 1,
    label: "VP Trần Não",
    value: "tran-nao",
  },
  {
    id: 2,
    label: "Tầng 6",
    value: "floor-six",
  },
  {
    id: 3,
    label: "Tầng 7",
    value: "floor-seven",
  },
  {
    id: 4,
    label: "Tầng 8",
    value: "floor-eighth",
  },
  {
    id: 5,
    label: "Tầng 9",
    value: "floor-ninth",
  },
  {
    id: 6,
    label: "Tầng 10",
    value: "floor-ten",
  },
];
